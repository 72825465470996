// code by ihtizaz that we have done for pagination

// import ReactPaginate from "react-paginate";
// import React, { useRef, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchProducts } from "../../redux/slices/productSlice";
// import {
//   addToWishlist,
//   removeFromWishlist,
// } from "../../redux/slices/wishlistSlice";
// import icon1 from "../../assets/images/Chevron left.png";
// import icon2 from "../../assets/images/Chevron right.png";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./snacks.css";
// import "../../../src/index.css";
// import API from "../../api/apiService";
// import { ENDPOINTS } from "../../api/apiRoutes";
// import { Link } from "react-router-dom";
// import { FaRegHeart, FaHeart } from "react-icons/fa";
// import { addToCart } from "../../redux/slices/cartSlice";
// import { toast } from "react-toastify";
// import { PiShoppingCartLight } from "react-icons/pi";
// import { TailSpin } from "react-loader-spinner";

// function Snacks() {
//   const { searchQuery } = useSelector((state) => state.product);
//   const wishlistItems = useSelector((state) => state.wishlist.items);
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const [categories, setCategories] = useState([]);
//   const [visibleCategoryIndex, setVisibleCategoryIndex] = useState(null);
//   const [count, setCount] = useState(1);
//   const sliderRefs = useRef([]);
//   const dispatch = useDispatch();
//   const [currentPage, setCurrentPage] = useState(1); // Start at page 1
//   const categoriesPerPage = 3;
//   const [totalPages, setTotalPages] = useState(0);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     dispatch(fetchProducts());
//   }, [dispatch]);

//   const fetchCategories = async (page) => {
//     setLoading(true);
//     try {
//       const response = await API.get(
//         `${ENDPOINTS.GET_ALLPRODUCTS}?page=${page}&limit=${categoriesPerPage}`
//       );

//       const uniqueCategoriesMap = new Map();

//       response.inventoryItems.forEach((item) => {
//         const categoryId = item.category?._id;
//         const categoryName = item.category?.name;

//         if (categoryId) {
//           if (uniqueCategoriesMap.has(categoryId)) {
//             uniqueCategoriesMap.get(categoryId).products.push(item);
//           } else {
//             uniqueCategoriesMap.set(categoryId, {
//               _id: categoryId,
//               name: categoryName,
//               products: [item],
//             });
//           }
//         }
//       });

//       const uniqueCategories = Array.from(uniqueCategoriesMap.values());
//       setCategories(uniqueCategories);
//       setTotalPages(response.totalPages);
//       setLoading(false);
//     } catch (err) {
//       console.error("Error fetching categories:", err);
//     }
//   };

//   useEffect(() => {
//     fetchCategories(currentPage);
//   }, [currentPage]);

//   const filteredCategories = categories
//     .map((category) => ({
//       ...category,
//       products:
//         category.products?.filter((product) =>
//           product.name.toLowerCase().includes(searchQuery.toLowerCase())
//         ) || [],
//     }))
//     .filter((category) => category.products.length > 0);

//   const handleAddToCart = (product) => {
//     dispatch(
//       addToCart({ ...product, quantity: count, stock: product.quantity })
//     );
//     toast.success("Item added to cart successfully!");
//   };

//   const offset = (currentPage - 1) * categoriesPerPage;
//   const displayedCategories = filteredCategories.slice(
//     offset,
//     offset + categoriesPerPage
//   );

//   const handlePageClick = ({ selected }) => {
//     setCurrentPage(selected + 1);
//   };

//   const settings = {
//     infinite: false,
//     speed: 1000,
//     slidesToShow: 5,
//     slidesToScroll: 3,
//     initialSlide: 0,
//     arrows: false,
//     centerMode: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: false,
//           dots: false,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           initialSlide: 2,
//           infinite: false,
//           dots: false,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           infinite: false,
//           dots: false,
//         },
//       },
//     ],
//   };

//   const handlePrevClick = (index) => {
//     if (sliderRefs.current[index]) {
//       sliderRefs.current[index].slickPrev();
//     }
//   };

//   const handleNextClick = (index) => {
//     if (sliderRefs.current[index]) {
//       sliderRefs.current[index].slickNext();
//     }
//   };

//   const handleSeeMoreClick = (index) => {
//     setVisibleCategoryIndex(visibleCategoryIndex === index ? null : index);
//   };

//   const handleAddToWishlist = (e, product) => {
//     e.stopPropagation();
//     const isInWishlist = wishlistItems.some((item) => item._id === product._id);
//     if (isInWishlist) {
//       dispatch(removeFromWishlist(product._id));
//     } else {
//       dispatch(addToWishlist(product));
//     }
//   };

//   return (
//     <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px] ">
//     {loading ? (
//         <div className="flex justify-center items-center h-[400px]">
//         <TailSpin
//           height="80"
//           width="80"
//           color="#4fa94d"
//           ariaLabel="tail-spin-loading"
//           radius="1"
//           visible={true}
//         />
//         </div>
//       ) :
//       filteredCategories.length > 0 ? (
//         filteredCategories.map((category, index) => (
//           <div key={category._id} className="mb-8">
//             <div className="flex justify-between">
//               <div>
//                 <p className="MediumFont text-2xl text-[#344054]">
//                   {category.name}
//                 </p>
//               </div>
//               <div className="flex gap-[16px]">
//                 <button
//                   className="text-xl text-[#003E00] MediumFont underline"
//                   onClick={() => handleSeeMoreClick(index)}
//                 >
//                   {visibleCategoryIndex === index ? "See Less" : "See All"}
//                 </button>
//                 <img
//                   src={icon1}
//                   alt="Previous"
//                   onClick={() => handlePrevClick(index)}
//                   className="cursor-pointer"
//                 />
//                 <img
//                   src={icon2}
//                   alt="Next"
//                   onClick={() => handleNextClick(index)}
//                   className="cursor-pointer"
//                 />
//               </div>
//             </div>

//             <div className="mt-6 w-full ">
//               {category.products.length > 0 ? (
//                 visibleCategoryIndex === index ? (
//                   <div className="grid xl:grid-cols-5 lg:grid-cols-4  md:grid-cols-4 sm:grid-cols-2 grid-cols-1  gap-5 px-[10px]">
//                     {category.products.map((product) => (
//                       <Link
//                         key={product._id}
//                         to={`/product/${product._id}`}
//                         className="flex flex-col gap-4 shadow-md rounded-2xl overflow-hidden relative group"
//                       >
//                         <div className="relative">
//                           <div className="">
//                             <img
//                               src={product.imageUrl}
//                               alt=""
//                               className="bg-cover md:h-[200px] w-full rounded-b-xl"
//                             />
//                           </div>
//                           <div
//                             className="absolute top-1 right-2 w-[44px] h-[44px] rounded-full flex items-center justify-center cursor-pointer"
//                             onClick={(e) => {
//                               e.preventDefault();
//                               e.stopPropagation();
//                               handleAddToWishlist(e, product);
//                             }}
//                           >
//                             {wishlistItems.some(
//                               (item) => item._id === product._id
//                             ) ? (
//                               <FaHeart className="text-red-500" size={28} />
//                             ) : (
//                               <FaRegHeart className="text-white" size={28} />
//                             )}
//                           </div>
//                         </div>

//                         <div className="flex flex-col gap-2 items-center justify-center mb-1  px-4  ">
//                         <p className="MediumFont text-xl text-[#344054] text-center  ">
//                             {product.name.length > 14
//                               ? product.name.slice(0, 14) + ".."
//                               : product.name}
//                           </p>
//                           <p className=" text-xl text-gray-500 text-center">
//                             {category.name}
//                           </p>
//                           {product.retailPrice > 0 && (
//                             <p className="text-[16px] text-[#DC4D41] line-through RegularFont">
//                               Eur {product.retailPrice}
//                             </p>
//                           )}
//                           <p className="font-medium text-lg text-[#344054] my-2 MediumFont">
//                             Eur {product.price}
//                           </p>
//                         </div>

//                         <div className="item-center justify-center px-4 py-2 hidden group-hover:block">
//                           {" "}
//                           <div>
//                           <p className="font-medium text-center text-base  text-[#344054] ">
//                               {product.description.split(" ").length > 8
//                                 ? product.description
//                                     .split(" ")
//                                     .slice(0, 8)
//                                     .join(" ") + " ..."
//                                 : product.description}
//                             </p>
//                           </div>
//                           <div className="flex items-center flex-col gap-2 ">
//                             <button
//                               onClick={(e) => {
//                                 e.preventDefault();
//                                 e.stopPropagation();
//                                 handleAddToCart(product);
//                               }}
//                               disabled={cartItems.some(
//                                 (item) => item._id === product._id
//                               )}
//                               className={`flex items-center justify-center ${
//                                 cartItems.some(
//                                   (item) => item._id === product._id
//                                 )
//                                   ? "bg-gray-300 cursor-not-allowed"
//                                   : "bg-white"
//                               } rounded-full p-[5px]`}
//                             >
//                               <PiShoppingCartLight size={32} color="#003E00" />
//                             </button>
//                           </div>
//                         </div>
//                       </Link>
//                     ))}
//                   </div>
//                 ) : (
//                   <Slider
//                     ref={(el) => (sliderRefs.current[index] = el)}
//                     {...settings}
//                     className="gap-[10px] "
//                   >
//                     {category.products.map((product) => (
//                       <Link
//                         key={product._id}
//                         to={`/product/${product._id}`}
//                         className="flex flex-col gap-4 shadow-md rounded-2xl overflow-hidden relative group"
//                       >
//                         <div className="relative">
//                           <div className="">
//                             <img
//                               src={product.imageUrl}
//                               alt=""
//                               className="bg-cover md:h-[200px] w-full rounded-b-xl"
//                             />
//                           </div>
//                           <div
//                             className="absolute top-1 right-2 w-[44px] h-[44px] rounded-full flex items-center justify-center cursor-pointer"
//                             onClick={(e) => {
//                               e.preventDefault();
//                               e.stopPropagation();
//                               handleAddToWishlist(e, product);
//                             }}
//                           >
//                             {wishlistItems.some(
//                               (item) => item._id === product._id
//                             ) ? (
//                               <FaHeart className="text-red-500" size={28} />
//                             ) : (
//                               <FaRegHeart className="text-white" size={28} />
//                             )}
//                           </div>
//                         </div>

//                         <div className="flex flex-col gap-2 items-center justify-center my-4  px-4 ">
//                           <p className="MediumFont text-xl text-[#344054] text-center w-[180px] ">
//                             {product.name.length > 24
//                               ? product.name.slice(0, 24) + ".."
//                               : product.name}
//                           </p>
//                           <p className=" text-xl text-gray-500 text-center">
//                             {category.name}
//                           </p>

//                           {product.retailPrice > 0 && (
//                             <p className="text-[16px] text-[#DC4D41] line-through RegularFont">
//                               Eur {product.retailPrice}
//                             </p>
//                           )}
//                           <p className="font-medium text-lg my-2 text-[#344054] MediumFont">
//                             Eur {product.price}
//                           </p>
//                         </div>
//                         <div className="item-center justify-center px-4 py-2 hidden group-hover:block">
//                           {" "}
//                           <div>
//                             <p className="font-medium text-center text-base  text-[#344054] ">
//                               {product.description.split(" ").length > 8
//                                 ? product.description
//                                     .split(" ")
//                                     .slice(0, 8)
//                                     .join(" ") + " ..."
//                                 : product.description}
//                             </p>
//                           </div>
//                           <div className="flex items-center flex-col gap-2 ">
//                             <button
//                               onClick={(e) => {
//                                 e.preventDefault();
//                                 e.stopPropagation();
//                                 handleAddToCart(product);
//                               }}
//                               disabled={cartItems.some(
//                                 (item) => item._id === product._id
//                               )}
//                               className={`flex items-center justify-center ${
//                                 cartItems.some(
//                                   (item) => item._id === product._id
//                                 )
//                                   ? "bg-gray-300 cursor-not-allowed"
//                                   : "bg-white"
//                               } rounded-full p-[5px]`}
//                             >
//                               <PiShoppingCartLight size={32} color="#003E00" />
//                             </button>
//                           </div>
//                         </div>
//                       </Link>
//                     ))}
//                   </Slider>
//                 )
//               ) : (
//                 <p>No products found in this category.</p>
//               )}
//             </div>
//           </div>
//         ))
//       ) : (
//         <p>No categories found.</p>
//       )}

//       <div className="mt-20">
//         <ReactPaginate
//           previousLabel={"Previous"}
//           nextLabel={"Next"}
//           breakLabel={"..."}
//           pageCount={totalPages}
//           marginPagesDisplayed={2}
//           pageRangeDisplayed={3}
//           onPageChange={handlePageClick}
//           containerClassName={"pagination"}
//           pageClassName={"page-item"}
//           pageLinkClassName={"page-link"}
//           previousClassName={"page-item"}
//           previousLinkClassName={"page-link"}
//           nextClassName={"page-item"}
//           nextLinkClassName={"page-link"}
//           activeClassName={"active"}
//         />
//       </div>
//     </div>
//   );
// }

// export default Snacks;

import ReactPaginate from "react-paginate";
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/slices/productSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/slices/wishlistSlice";
import icon1 from "../../assets/images/Chevron left.png";
import icon2 from "../../assets/images/Chevron right.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./snacks.css";
import "../../../src/index.css";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";
import { Link } from "react-router-dom";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { addToCart } from "../../redux/slices/cartSlice";
import { toast } from "react-toastify";
import { PiShoppingCartLight } from "react-icons/pi";
import { TailSpin } from "react-loader-spinner";

function Snacks() {
  const { searchQuery } = useSelector((state) => state.product);
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [categories, setCategories] = useState([]);
  const [visibleCategoryIndex, setVisibleCategoryIndex] = useState(null);
  const [count, setCount] = useState(1);
  const sliderRefs = useRef([]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1); // Start at page 1
  const categoriesPerPage = 3;
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      const response = await API.get(
        `${ENDPOINTS.GET_ALLPRODUCTS}?page=${page}&limit=${categoriesPerPage}`
      );

      const uniqueCategoriesMap = new Map();

      response.inventoryItems.forEach((item) => {
        const categoryId = item.category?._id;
        const categoryName = item.category?.name;

        if (categoryId) {
          if (uniqueCategoriesMap.has(categoryId)) {
            uniqueCategoriesMap.get(categoryId).products.push(item);
          } else {
            uniqueCategoriesMap.set(categoryId, {
              _id: categoryId,
              name: categoryName,
              products: [item],
            });
          }
        }
      });

      const uniqueCategories = Array.from(uniqueCategoriesMap.values());
      setCategories(uniqueCategories);
      setTotalPages(response.totalPages);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  useEffect(() => {
    fetchCategories(currentPage);
  }, [currentPage]);

  const filteredCategories = categories
    .map((category) => ({
      ...category,
      products:
        category.products?.filter((product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) || [],
    }))
    .filter((category) => category.products.length > 0);

  const handleAddToCart = (product) => {
    dispatch(
      addToCart({ ...product, quantity: count, stock: product.quantity })
    );
    toast.success("Item added to cart successfully!");
  };

  const offset = (currentPage - 1) * categoriesPerPage;
  const displayedCategories = filteredCategories.slice(
    offset,
    offset + categoriesPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    window.scrollTo(0, 0);
  };

  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const handlePrevClick = (index) => {
    if (sliderRefs.current[index]) {
      sliderRefs.current[index].slickPrev();
    }
  };

  const handleNextClick = (index) => {
    if (sliderRefs.current[index]) {
      sliderRefs.current[index].slickNext();
    }
  };

  const handleSeeMoreClick = (index) => {
    setVisibleCategoryIndex(visibleCategoryIndex === index ? null : index);
  };

  const handleAddToWishlist = (e, product) => {
    e.stopPropagation();
    const isInWishlist = wishlistItems.some((item) => item._id === product._id);
    if (isInWishlist) {
      dispatch(removeFromWishlist(product._id));
    } else {
      dispatch(addToWishlist(product));
    }
  };

  return (
    <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px] ">
      {loading ? (
        <div className="flex justify-center items-center h-[400px]">
          <TailSpin
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      ) : filteredCategories.length > 0 ? (
        filteredCategories.map((category, index) => (
          <div key={category._id} className="mb-8">
            <div className="flex justify-between w-full items-center ">
              <div className=" ">
                <p className="MediumFont md:text-2xl text-base text-[#344054] ">
                  {category.name}
                </p>
              </div>
              <div className="flex md:gap-[16px] gap-[2px]  ">
                <button
                  className="md:text-xl text-base text-[#003E00] MediumFont underline"
                  onClick={() => handleSeeMoreClick(index)}
                >
                  {visibleCategoryIndex === index ? "See Less" : "See All"}
                </button>
                <img
                  src={icon1}
                  alt="Previous"
                  onClick={() => handlePrevClick(index)}
                  className="cursor-pointer  "
                />
                <img
                  src={icon2}
                  alt="Next"
                  onClick={() => handleNextClick(index)}
                  className="cursor-pointer"
                />
              </div>
            </div>

            <div className="mt-6 w-full  ">
              {category.products.length > 0 ? (
                visibleCategoryIndex === index ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 px-2 ">
                    {category.products.map((product) => (
                      <Link
                        key={product._id}
                        to={`/product/${product._id}`}
                        className="flex flex-col gap-4 md:h-[380px] h-[520px] group shadow-md rounded-2xl overflow-hidden relative transition-all duration-300 md:hover:h-[500px] hover:h-[620px]"
                     
                      >
                        <div className="relative">
                          <img
                            src={product.imageUrl}
                            alt=""
                            className="bg-cover md:h-[200px] w-full rounded-b-xl"
                          />
                          <div
                            className="absolute top-1 right-2 w-[44px] h-[44px] rounded-full flex items-center justify-center cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddToWishlist(e, product);
                            }}
                          >
                            {wishlistItems.some(
                              (item) => item._id === product._id
                            ) ? (
                              <FaHeart className="text-red-500" size={28} />
                            ) : (
                              <FaRegHeart className="text-gray-300" size={28} />
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col gap-2 items-center justify-center my-4 px-4">
                          <p className="MediumFont text-xl text-[#344054] text-center w-[180px]">
                            {product.name.length > 24
                              ? product.name.slice(0, 24) + "..."
                              : product.name}
                          </p>
                          <p className="text-xl text-gray-500 text-center">
                            {category.name}
                          </p>

                          {product.retailPrice > 0 && (
                            <p className="text-[16px] text-[#DC4D41] line-through RegularFont">
                              Eur {product.retailPrice}
                            </p>
                          )}
                          <p className="font-medium text-lg my-2 text-[#344054] MediumFont">
                            Eur {product.price}
                          </p>
                        </div>

                        {/* Hidden description and button, visible on hover */}
                        <div className="absolute bottom-0 left-0 right-0 px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <p className="font-medium text-center text-base text-[#344054]">
                            {product.description.split(" ").length > 10
                              ? product.description
                                  .split(" ")
                                  .slice(0, 10)
                                  .join(" ") + "..."
                              : product.description}
                          </p>
                          <div className="flex items-center flex-col gap-2 mt-2">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleAddToCart(product);
                              }}
                              disabled={cartItems.some(
                                (item) => item._id === product._id
                              )}
                              className={`flex items-center justify-center ${
                                cartItems.some(
                                  (item) => item._id === product._id
                                )
                                  ? "bg-gray-300 cursor-not-allowed"
                                  : "bg-white"
                              } rounded-full p-[5px]`}
                            >
                              <PiShoppingCartLight size={32} color="#003E00" />
                            </button>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <Slider
                    ref={(el) => (sliderRefs.current[index] = el)}
                    {...settings}
                    className="gap-[10px]"
                  >
                    {category.products.map((product) => (
                      <Link
                        key={product._id}
                        to={`/product/${product._id}`}
                        className="flex flex-col gap-4 md:h-[380px] group shadow-md rounded-2xl overflow-hidden relative transition-all duration-300 md:hover:h-[500px] hover:h-[620px]"
                      >
                        <div className="relative">
                          <img
                            src={product.imageUrl}
                            alt=""
                            className="bg-cover md:h-[200px]  w-full rounded-b-xl "
                          />
                          <div
                            className="absolute top-1 right-2 w-[44px] h-[44px] rounded-full flex items-center justify-center cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddToWishlist(e, product);
                            }}
                          >
                            {wishlistItems.some(
                              (item) => item._id === product._id
                            ) ? (
                              <FaHeart className="text-red-500" size={28} />
                            ) : (
                              <FaRegHeart className="text-gray-300" size={28} />
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col gap-2 items-center justify-center my-4 px-4">
                          <p className="MediumFont text-xl text-[#344054] text-center w-[180px]">
                            {product.name.length > 24
                              ? product.name.slice(0, 24) + "..."
                              : product.name}
                          </p>
                          <p className="text-xl text-gray-500 text-center">
                            {category.name}
                          </p>

                          {product.retailPrice > 0 && (
                            <p className="text-[16px] text-[#DC4D41] line-through RegularFont">
                              Eur {product.retailPrice}
                            </p>
                          )}
                          <p className="font-medium text-lg my-2 text-[#344054] MediumFont">
                            Eur {product.price}
                          </p>
                        </div>

                        {/* Hidden description and button, visible on hover */}
                        <div className="absolute bottom-0 left-0 right-0 px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <p className="font-medium text-center text-base text-[#344054]">
                            {product.description.split(" ").length > 10
                              ? product.description
                                  .split(" ")
                                  .slice(0, 10)
                                  .join(" ") + "..."
                              : product.description}
                          </p>
                          <div className="flex items-center flex-col gap-2 mt-2">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleAddToCart(product);
                              }}
                              disabled={cartItems.some(
                                (item) => item._id === product._id
                              )}
                              className={`flex items-center justify-center ${
                                cartItems.some(
                                  (item) => item._id === product._id
                                )
                                  ? "bg-gray-300 cursor-not-allowed"
                                  : "bg-white"
                              } rounded-full p-[5px]`}
                            >
                              <PiShoppingCartLight size={32} color="#003E00" />
                            </button>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </Slider>
                )
              ) : (
                <p>No products found in this category.</p>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No categories found.</p>
      )}

      <div className="mt-20">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}

export default Snacks;
