import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Landingpage from "../../components/LandingPage/Landingpage";
import FoodSection from "../../components/FoodSection/FoodSection";
import Snacks from "../../components/SnacksSection/Snacks";
import Collection from "../../components/ExploreCollection/Collection";
import Footer from "../../components/Footer/Footer";
import Bannercomp from "../../components/Bannercomp/Bannercomp";

export default function Home() {
  return (
    <>
      <Navbar />
      <Landingpage />
      <FoodSection />
      <Snacks />
      <Bannercomp />
      <Collection />
      <Footer />
    </>
  );
}
