import {React,useState} from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import API from "../../../../api/apiService";
import { ENDPOINTS } from "../../../../api/apiRoutes";
import login from "../../../../assets/images/login.png";
import "../../../../index.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const validationSchema = Yup.object({
  username: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Register = () => {
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate();

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus }
  ) => {
    try {
      
       // Assuming the API response contains the userName
       const userName = values.username; 

       // Store userName in localStorage
       localStorage.setItem("userName", JSON.stringify(userName));
      const response = await API.post(ENDPOINTS.REGISTER, values);
      console.log(response);
      setStatus({
        success:
          "Registration successful! Please check your email for verification.",
      });
      navigate("/verification");
    } catch (error) {
      setErrors({
        api:
          error.response?.message || "An error occurred during registration.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="w-[100%]">
      <div className="md:flex justify-center">
        <div className="md:w-[50%] md:h-[100vh] bg-[#004D00]  flex items-end">
          <img src={login} alt="product" className="" />
        </div>
        <div className="md:w-[50%] rounded-2xl flex flex-col justify-center bg-white items-center md:mt-0 mt-5">
          <div>
            <h1 className="md:text-4xl text-3xl font-bold">Welcome Back!</h1>
            <p className="text-xl font-semibold mt-4 mb-6">
              Signup to your account
            </p>

            <Formik
              initialValues={{ username: "", email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, status }) => (
                <Form className="flex flex-col gap-2 md:w-[450px]">
                  <label className="font-semibold">UserName</label>
                  <Field
                    type="text"
                    name="username"
                    placeholder="Enter your Name"
                    className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="text-red-500"
                  />

                  <label className="md:mt-2 mt-2 font-semibold">Email</label>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter your Email"
                    className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />

                  <label className="md:mt-2 mt-2 font-semibold">Password</label>
                  <div className="relative">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter your Password"
                        className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                      />
                      <span
                        className="absolute right-2 top-3 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <IoEyeOutline size={20} /> : <IoEyeOffOutline size={20} />}
                      </span>
                    </div>
                    <ErrorMessage name="password" component="div" className="text-red-500" />
                

                  {/* {status?.success && (
                    <p className="text-green-500 mt-4">{status.success}</p>
                  )}
                  {status?.api && (
                    <p className="text-red-500 mt-4">{status.api}</p>
                  )} */}

                  <button
                    type="submit"
                    className="w-full py-[10px] px-3 bg-[#004D00] text-white rounded-xl text-[16px] mt-6 inter-Semi"
                    disabled={handleSubmit}
                  >
                    Signup
                  </button>
                </Form>
              )}
            </Formik>

            <p className="font-semibold text-center mt-8">
              Already have an account?{" "}
              <button
                className="text-[#004D00]"
                onClick={() => navigate("/login")}
              >
                Login!
              </button>
            </p>
          </div>
          <p className="text-gray-400 mt-6 font-light">
            © 2024 Designed by Oliver | All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
