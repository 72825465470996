import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BiMinus, BiPlus } from "react-icons/bi";
import { PiShoppingCartLight } from "react-icons/pi";
import API from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/slices/cartSlice";
import { toast } from "react-toastify";

function Product() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await API.get(`/inventory/${id}`);
        setProduct(response);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleIncrement = () => {
    if (product && count < product.quantity) {
    setCount(count + 1);
    }
  };

  const handleDecrement = () => {
    if (count >= 2) {
      setCount(count - 1);
    }
  };
  const handleAddToCart = () => {
    if (product) {
      dispatch(
        addToCart({ ...product, quantity: count, stock: product.quantity })
      );
      toast.success("Item Added To Cart");
    }
  };

  if (!product) {
    return <p>Loading product details...</p>;
  }

  return (
    <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px]">
      <div className="flex md:flex-row flex-col  w-[100%] border border-gray-200 rounded-lg ">
        <div>
          <img
            src={product.imageUrl || product}
            alt="product"
            className="w-full md:h-[450px] z-0 rounded-md"
          />
        </div>
        <div className=" md:w-[704px] z-10 relative md:-left-10  bg-white md:p-6 p-4 ">
          <h1 className="md:text-[36px] text-[28px] MediumFont">
            {product.name}
          </h1>
          <p className="text-2xl MediumFont my-4">£ {product.price}</p>
          <p className="text-xl font-semibold ">Description</p>
          <p className="text-gray-400 mb-4">{product.description}</p>
          <div className="flex gap-2 mb-4">
            <p className="text-xl font-semibold">Quantity</p>

            <div className="flex gap-4 bg-gray-200  py-1 px-3  mx-3">
              <BiMinus
                className={`self-center cursor-pointer ${
                  count === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleDecrement}
              />
              <p>{count}</p>
              <BiPlus
                className="self-center cursor-pointer"
                onClick={handleIncrement}
              />
            </div>
          </div>
          <div>
            <div className="flex mb-4">
              <div className="md:w-[200px] w-[120px]">
                <p className="text-xl font-semibold">Unit</p>
                <h2 className="text-base NormalFont">{product.unitType}</h2>
              </div>
              <div className="relative left-24">
                <p className="text-xl font-semibold">Status</p>
                <h2 className="text-base NormalFont">
                  {product.isActive ? "In stock" : "Out of stock"}
                </h2>
              </div>
            </div>
            <div className="flex gap-8 mb-4">
              {/* <button  className='w-full p-3 text-center bg-[#004D00] text-white MediumFont  text-sml'  onClick={handleAddToCart}>Buy Now</button> */}
              <button
                className="w-[250px] p-3 text-center bg-[#004D00] text-white MediumFont rounded-3xl text-xl"
                onClick={handleAddToCart}
              >
                Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
