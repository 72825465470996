import React from 'react';
import flagicon from "../../assets/images/flagicon.png";
import xclose from "../../assets/images/x-close.png";
import bannerfood from "../../assets/images/bannerfood.png";
import "../../index.css";

function AddFilterModal({ handleCloseModal }) {
  return (
    <>
      
      <div className="fixed inset-0 bg-gray-300 bg-opacity-50 backdrop-blur-sm z-40"></div>

  
      <div className='fixed inset-0 flex items-center justify-center z-50'>
        <div className='p-6 border-2 rounded-md w-[30%] bg-white'>
          <div className='flex justify-between'>
            <div className="p-2 border border-gray-300 rounded-md">
              <img src={flagicon} alt="Flag Icon" />
            </div>
            <div>
              <img 
                src={xclose} 
                alt="Close Icon" 
                onClick={handleCloseModal}
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className='mt-6 flex flex-col gap-5'>
            <div>
              <h1 className='text-[#101828] inter-Semi text-[18px]'>Edit Banner</h1>
            </div>


            <div className='flex flex-col gap-2'>
              <p className='text-[#344054] MediumFont text-sm'>Name</p>
              <input type="text" placeholder='Sale' className='border border-gray-300 outline-none p-2 rounded-md'/>
            </div>

            <div className='flex flex-col gap-2'>
              <p className='text-[#344054] MediumFont text-sm'>Banner Image</p>
              <div className='flex  items-center justify-center'>
             <img src={bannerfood} alt="" />
              </div>
              
            <label class="relative inline-flex items-center cursor-pointer mt-2">
      <input type="checkbox" value="" class="sr-only peer"/>
      <div class="w-9 h-5 bg-gray-200 hover:bg-gray-300 peer-focus:outline-0 peer-focus:ring-transparent rounded-full peer transition-all ease-in-out duration-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-indigo-600 hover:peer-checked:bg-indigo-700"></div>
      </label>
            </div>

            <div className='flex gap-4 items-center justify-center'>
              <div>
                <button 
                  onClick={handleCloseModal}
                  className="text-[#003E00] text-sm inter-Semi bg-[#F9FFF5] py-2 px-10 rounded-lg"
                >
                  Cancel
                </button>
              </div>
              <div>
                <button className="bg-[#003E00] text-sm inter-Semi text-white py-2 px-10 rounded-lg">
                  Add Banner
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddFilterModal;
