import React from "react";
import img1 from "../../assets/images/fb.png";
import img2 from "../../assets/images/insta.png";
import img3 from "../../assets/images/X.png";
import img4 from "../../assets/images/EURO FRESH MART.png";
import "../../index.css";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer class="bg-[#192732] text-white py-8 ">
        <div class="container mx-auto px-4 ">
          <div class="flex flex-wrap flex-row justify-center items-center md:items-start gap-8">
            <div class="mb-6 md:mb-0 text-center md:text-left">
              <a href="#" />
              <p className="md:text-4xl text-3xl BBoldFont">EUROFRESH MART</p>

              <p class="mt-2 NormalFont text-lg">
                Your one-stop shop for all European goods.
              </p>
              <div className="mt-8 flex justify-center">
  <a href="https://www.facebook.com" className="hover:text-gray-300 mx-2" target="_blank" rel="noopener noreferrer">
    <FaFacebookF size={20} />
  </a>
  <a href="https://twitter.com" className="hover:text-gray-300 mx-2" target="_blank" rel="noopener noreferrer">
    <FaTwitter size={20} />
  </a>
  <a href="https://www.instagram.com" className="hover:text-gray-300 mx-2" target="_blank" rel="noopener noreferrer">
    <FaInstagram size={20} />
  </a>
  <a href="https://www.linkedin.com" className="hover:text-gray-300 mx-2" target="_blank" rel="noopener noreferrer">
    <FaLinkedinIn size={20} />
  </a>
</div>

            </div>

            <div class="flex flex-wrap justify-around w-full md:w-2/3 mt-6 md:mt-0 text-center md:text-left gap-6">
              <div class="mb-4 md:mb-0 md:w-1/4">
                <h4 class=" mb-4 text-2xl BoldFont ">Company</h4>
                <ul className="flex flex-col gap-2">
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      Press
                    </a>
                  </li>
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mb-4 md:mb-0 md:w-1/4">
                <h4 class=" mb-3 text-2xl BoldFont">Support</h4>
                <ul className="flex flex-col gap-2">
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      Help Center
                    </a>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      class="hover:text-gray-300 NormalFont text-lg"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      Returns
                    </a>
                  </li>
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      Order Tracking
                    </a>
                  </li>
                </ul>
              </div>
              <div class="md:w-1/4">
                <h4 class=" mb-3 text-2xl BoldFont">Legal</h4>
                <ul className="flex flex-col gap-2">
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a href="#" class="hover:text-gray-300 NormalFont text-lg">
                      Cookie Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="mt-16 text-center">
            <p class="text-gray-400 text-lg">
              &copy; 2024 Euromart. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
