// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { setSearchQuery } from "../../redux/slices/productSlice";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { PiShoppingCartLight } from "react-icons/pi";
// import { IoMdMenu, IoMdClose, IoIosSettings } from "react-icons/io";
// import { RiArrowDropDownLine } from "react-icons/ri";
// import CartSlider from "../../components/CartSlider/CartSlider";
// import Logo from "../../assets/images/EURO FRESH MART.png";
// import icon1 from "../../assets/images/search-lg.png";
// import { CiHeart, CiUser } from "react-icons/ci";
// import { FaRegUserCircle } from "react-icons/fa";
// import { CgLogOut } from "react-icons/cg";
// import WishlistSlider from "../../components/WishlistSlider/WishlistSlider";
// import { logout } from "../../redux/slices/loginSlice";
// import "./Navbar.css"

// function Navbar() {
//   const [query, setQuery] = useState("");
//   const { isLoggedIn, Admin } = useSelector((state) => state.login);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [isCartSliderOpen, setIsCartSliderOpen] = useState(false);
//   const [isWishlistSliderOpen, setIsWishlistSliderOpen] = useState(false);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [userName, setUserName] = useState("");

//   const dispatch = useDispatch();
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const wishlistItems = useSelector((state) => state.wishlist.items);
//   const location = useLocation();
//   const isHomePage = location.pathname === "/";
//   const navigate = useNavigate();
//   const state = useSelector((state) => state.categories);

//   useEffect(() => {
//     const storedUserName = JSON.parse(localStorage.getItem("userName"));
//     setUserName(storedUserName || "");
//   }, []);

//   const handleSearch = (e) => {
//     setQuery(e.target.value);
//     dispatch(setSearchQuery(e.target.value));
//   };

//   const toggleCartSlider = () => {
//     setIsCartSliderOpen(!isCartSliderOpen);
//   };

//   const toggleWishlistSlider = () => {
//     setIsWishlistSliderOpen(!isWishlistSliderOpen);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("userName");
//     dispatch(logout());
//     setUserName("");
//     setIsDropdownOpen(false);
//     navigate("/");
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   return (
//     <div className="sticky top-0 bg-white py-[24px] px-[16px] sm:px-[32px] flex items-center justify-between shadow-md z-50 gap-6">
//       <div className="flex gap-[16px] sm:gap-[32px] items-center">
//         <Link to="/">
//           <img
//             src={Logo}
//             alt="Logo"
//             className="w-[200px] sm:w-auto cursor-pointer"
//           />
//         </Link>
//         <div className="hidden md:flex md:w-[320px] items-center justify-between border border-[#D0D5DD] rounded-2xl py-[8px] px-[12px]">
//           <input
//             type="text"
//             value={query}
//             onChange={handleSearch}
//             placeholder="Search products..."
//             className="outline-none w-full"
//           />
//           <img src={icon1} alt="Search" />
//         </div>
//       </div>

//       <div className="hidden md:flex gap-[24px] items-center">
//         <Link
//           to="/"
//           className="MediumFont text-lg text-[#344054] font-sans hover:underline"
//         >
//           Home
//         </Link>
//         <Link
//           to={`/orderPlacement?name=${state?.items[0]?.name}&id=${state?.items[0]?._id}`}
//           className="MediumFont text-lg text-[#344054] font-sans  hover:underline"
//         >
//           Products
//         </Link>
//         <Link
//           to="/contact"
//           className="MediumFont text-lg text-[#344054] font-sans  hover:underline"
//         >
//           Contact
//         </Link>
//       </div>

//       <div className="flex gap-[16px] items-center">
//         <div className="relative">
//           <button onClick={toggleWishlistSlider}>
//             <CiHeart size={32} />
//             {wishlistItems.length > 0 && (
//               <span className="absolute top-0 right-0 bg-[#003E00] text-white text-xs rounded-full px-1">
//                 {wishlistItems.length}
//               </span>
//             )}
//           </button>
//         </div>
//         <WishlistSlider
//           isOpen={isWishlistSliderOpen}
//           onClose={toggleWishlistSlider}
//           wishlistItems={wishlistItems}
//         />
//         <div className="relative">
//           <button onClick={toggleCartSlider}>
//             <PiShoppingCartLight size={32} />
//           </button>
//           {cartItems.length > 0 && (
//             <span className="absolute top-0 right-0 bg-[#003E00] text-white text-xs rounded-full px-1">
//               {cartItems.length}
//             </span>
//           )}
//         </div>

//         {isLoggedIn && (
//           <div className="hidden md:flex gap-[10px] items-center">
//             <CiUser size={32} />
//             <p className="MediumFont text-sm text-[#344054] font-sans">
//               {userName}
//             </p>
//             <button onClick={toggleDropdown}>
//               <RiArrowDropDownLine size={32} />
//             </button>

//             {isDropdownOpen && (
//               <div className="absolute top-[80px] right-8 mt-2 w-[15%] bg-white rounded-md shadow-lg">
//                 <ul className="py-1">
//                   <li className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 flex items-center gap-3 MediumFont">
//                     <CiUser size={32} /> {userName}
//                   </li>
//                   <li className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 flex items-center gap-3 MediumFont">
//                     <FaRegUserCircle size={30} /> Profile
//                   </li>
//                   <li className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 flex items-center gap-3 MediumFont">
//                     <IoIosSettings size={30} /> Settings
//                   </li>
//                   <li
//                     className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer flex items-center gap-3 MediumFont"
//                     onClick={handleLogout}
//                   >
//                     <CgLogOut size={30} /> Logout
//                   </li>
//                 </ul>
//               </div>
//             )}
//           </div>
//         )}

//         {!isLoggedIn && (
//           <div className="hidden sm:flex gap-2">
//             <Link
//               to="/login"
//               className="MediumFont text-base text-[#344054] font-sans border rounded-md py-2 px-2"
//             >
//               Login
//             </Link>
//           </div>
//         )}
//       </div>

//       {/* Mobile menu button */}
//       <div className="sm:hidden">
//         <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
//           {isMobileMenuOpen ? (
//             <IoMdClose size={24} color="#344054" />
//           ) : (
//             <IoMdMenu size={24} color="#344054" />
//           )}
//         </button>
//       </div>

//       {/* Mobile menu */}
//       {isMobileMenuOpen && (
//         <div className="absolute top-[86px] left-0 w-full border-t shadow-md border-gray-300 bg-white z-20 flex flex-col px-4 py-4 ">
//           <div className="w-[260px] flex items-center justify-between border border-[#D0D5DD] rounded-2xl py-[8px] px-[12px]">
//             <input
//               type="text"
//               value={query}
//               onChange={handleSearch}
//               placeholder="Search products..."
//               className="outline-none w-full"
//             />
//             <img src={icon1} alt="Search" />
//           </div>
//           <Link
//             to="/"
//             className="text-lg text-gray-700 py-2 font-bold hover:underline"
//             onClick={() => setIsMobileMenuOpen(false)}
//           >
//             Home
//           </Link>
//           <Link
//             to={`/orderPlacement?name=${state?.items[0]?.name}&id=${state?.items[0]?._id}`}
//             className="text-lg text-gray-700 py-2 font-bold hover:underline"
//             onClick={() => setIsMobileMenuOpen(false)}
//           >
//             Products
//           </Link>
//           <Link
//             to="/contact"
//             className="text-lg text-gray-700 py-2 font-bold hover:underline"
//             onClick={() => setIsMobileMenuOpen(false)}
//           >
//             Contact
//           </Link>
//           {!isLoggedIn ? (
//             <Link
//               to="/login"
//               className="text-lg text-gray-700 py-2 font-bold hover:underline"
//               onClick={() => setIsMobileMenuOpen(false)}
//             >
//               Login
//             </Link>
//           ) : (
//             <div className="flex flex-col items-start gap-1">
//               <div className="flex  gap-2 py-2 items-center">
//                 <CiUser size={32} />
//                 <p className="MediumFont text-sm text-[#344054] font-sans">
//                   {userName}
//                 </p>
//               </div>
//               <button
//                 onClick={handleLogout}
//                 className="text-lg text-gray-700 py-2 font-bold hover:underline"
//               >
//                 Logout
//               </button>
//             </div>
//           )}
//         </div>
//       )}

//       {/* Cart Slider */}
//        <CartSlider isOpen={isCartSliderOpen} onClose={toggleCartSlider} />

//       {/* Wishlist Slider */}
//       <WishlistSlider
//          isOpen={isWishlistSliderOpen}
//          onClose={toggleWishlistSlider}
//        />
//     </div>
//   );
// }

// export default Navbar;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../redux/slices/productSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PiShoppingCartLight } from "react-icons/pi";
import { IoMdMenu, IoMdClose, IoIosSettings } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import CartSlider from "../../components/CartSlider/CartSlider";
import Logo from "../../assets/images/EURO FRESH MART.png";
import icon1 from "../../assets/images/search-lg.png";
import { CiHeart, CiUser } from "react-icons/ci";
import { FaRegUserCircle } from "react-icons/fa";
import { CgLogOut } from "react-icons/cg";
import WishlistSlider from "../../components/WishlistSlider/WishlistSlider";
import { logout } from "../../redux/slices/loginSlice";
import "./Navbar.css";

function Navbar() {
  const [query, setQuery] = useState("");
  const { isLoggedIn, Admin } = useSelector((state) => state.login);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCartSliderOpen, setIsCartSliderOpen] = useState(false);
  const [isWishlistSliderOpen, setIsWishlistSliderOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userName, setUserName] = useState("");

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const navigate = useNavigate();
  const state = useSelector((state) => state.categories);

  useEffect(() => {
    const storedUserName = JSON.parse(localStorage.getItem("userName"));
    setUserName(storedUserName || "");
  }, []);

  const handleSearch = (e) => {
    setQuery(e.target.value);
    dispatch(setSearchQuery(e.target.value));
  };

  const toggleCartSlider = () => {
    setIsCartSliderOpen(!isCartSliderOpen);
  };

  const toggleWishlistSlider = () => {
    setIsWishlistSliderOpen(!isWishlistSliderOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    dispatch(logout());
    setUserName("");
    setIsDropdownOpen(false);
    navigate("/");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="sticky top-0 bg-white py-[24px] px-[16px] sm:px-[32px] flex items-center justify-between shadow-md z-50 gap-6">
      <div className="flex gap-[16px] sm:gap-[32px] items-center">
        <Link to="/">
          <img
            src={Logo}
            alt="Logo"
            className="w-[200px] sm:w-auto cursor-pointer"
          />
        </Link>
        <div className="hidden md:flex md:w-[320px] items-center justify-between border border-[#D0D5DD] rounded-2xl py-[8px] px-[12px]">
          <input
            type="text"
            value={query}
            onChange={handleSearch}
            placeholder="Search products..."
            className="outline-none w-full"
          />
          <img src={icon1} alt="Search" />
        </div>
      </div>

      <div className="hidden lg:flex gap-[24px] items-center">
        <Link
          to="/"
          className={`MediumFont text-lg font-sans hover:underline ${
            location.pathname === "/" ? "active-link" : "text-[#344054]"
          }`}
        >
          Home
        </Link>
        <Link
          to={`/orderPlacement?name=${state?.items[0]?.name}&id=${state?.items[0]?._id}`}
          className={`MediumFont text-lg font-sans hover:underline ${
            location.pathname === "/orderPlacement"
              ? "active-link"
              : "text-[#344054]"
          }`}
        >
          Products
        </Link>
        <Link
          to="/contact"
          className={`MediumFont text-lg font-sans hover:underline ${
            location.pathname === "/contact" ? "active-link" : "text-[#344054]"
          }`}
        >
          Contact
        </Link>
      </div>

      <div className="flex gap-[16px] items-center">
        <div className="relative">
          <button onClick={toggleWishlistSlider}>
            <CiHeart size={32} />
            {wishlistItems.length > 0 && (
              <span className="absolute top-0 right-0 bg-[#003E00] text-white text-xs rounded-full px-1">
                {wishlistItems.length}
              </span>
            )}
          </button>
        </div>
        <WishlistSlider
          isOpen={isWishlistSliderOpen}
          onClose={toggleWishlistSlider}
          wishlistItems={wishlistItems}
        />
        <div className="relative">
          <button onClick={toggleCartSlider}>
            <PiShoppingCartLight size={32} />
          </button>
          {cartItems.length > 0 && (
            <span className="absolute top-0 right-0 bg-[#003E00] text-white text-xs rounded-full px-1">
              {cartItems.length}
            </span>
          )}
        </div>

        {isLoggedIn && (
          <div className="hidden  lg:flex gap-[10px] items-center">
            <CiUser size={32} />
            <p className="MediumFont text-sm text-[#344054] font-sans">
              {userName}
            </p>
            <button onClick={toggleDropdown}>
              <RiArrowDropDownLine size={32} />
            </button>

            {isDropdownOpen && (
              <div className="absolute top-[80px] right-8 mt-2 w-[15%] bg-white rounded-md shadow-lg">
                <ul className="py-1">
                  <li className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 flex items-center gap-3 MediumFont">
                    <CiUser size={32} /> {userName}
                  </li>
                  <li className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 flex items-center gap-3 MediumFont">
                    <FaRegUserCircle size={30} /> Profile
                  </li>
                  <li className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 flex items-center gap-3 MediumFont">
                    <IoIosSettings size={30} /> Settings
                  </li>
                  <li
                    className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer flex items-center gap-3 MediumFont"
                    onClick={handleLogout}
                  >
                    <CgLogOut size={30} /> Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}

        {!isLoggedIn && (
          <div className="hidden lg:flex gap-2">
            <Link
              to="/login"
              className="MediumFont text-base text-[#344054] font-sans border rounded-md py-2 px-2"
            >
              Login
            </Link>
          </div>
        )}
      </div>

      {/* Mobile menu button */}
      <div className="lg:hidden flex ">
        <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          {isMobileMenuOpen ? (
            <IoMdClose size={24} color="#344054" />
          ) : (
            <IoMdMenu size={24} color="#344054" />
          )}
        </button>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="absolute top-[86px] left-0 w-full border-t shadow-md border-gray-300 bg-white z-20 flex flex-col px-4 py-4 ">
          <div className="w-[260px] flex items-center justify-between border border-[#D0D5DD] rounded-2xl py-[8px] px-[12px]">
            <input
              type="text"
              value={query}
              onChange={handleSearch}
              placeholder="Search products..."
              className="outline-none w-full"
            />
            <img src={icon1} alt="Search" />
          </div>
          <Link
            to="/"
            className="text-lg text-gray-700 py-2 font-bold hover:underline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Home
          </Link>
          <Link
            to={`/orderPlacement?name=${state?.items[0]?.name}&id=${state?.items[0]?._id}`}
            className="text-lg text-gray-700 py-2 font-bold hover:underline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Products
          </Link>
          <Link
            to="/contact"
            className="text-lg text-gray-700 py-2 font-bold hover:underline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Contact
          </Link>
          {!isLoggedIn ? (
            <Link
              to="/login"
              className="text-lg text-gray-700 py-2 font-bold hover:underline"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Login
            </Link>
          ) : (
            <div className="flex flex-col items-start gap-1">
              <div className="flex  gap-2 py-2 items-center">
                <CiUser size={32} />
                <p className="MediumFont text-sm text-[#344054] font-sans">
                  {userName}
                </p>
              </div>
              <button
                onClick={handleLogout}
                className="text-lg text-gray-700 py-2 font-bold hover:underline"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      )}

      {/* Cart Slider */}
      <CartSlider isOpen={isCartSliderOpen} onClose={toggleCartSlider} />

      {/* Wishlist Slider */}
      <WishlistSlider
        isOpen={isWishlistSliderOpen}
        onClose={toggleWishlistSlider}
      />
    </div>
  );
}

export default Navbar;
