import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import img2 from "../../assets/images/shopping-cart-02.png";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import CustomLink from "../CustomLink/CustomLink";
import {
  fetchInventories,
  clearInventories,
} from "../../redux/slices/inventorySlice";
import { CiSearch } from "react-icons/ci";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import "./OrderSearch.css";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/slices/wishlistSlice";
import { toast } from "react-toastify";
import { PiShoppingCartLight } from "react-icons/pi";
import { Link } from "react-router-dom";
import { addToCart } from "../../redux/slices/cartSlice";
import { TailSpin } from "react-loader-spinner";

function OrderSearch() {
  const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [isSizeOpen, setIsSizeOpen] = useState(false);
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [isPriceOpen, setIsPriceOpen] = useState(false);
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const dispatch = useDispatch();
  const { inventories, error } = useSelector((state) => state.inventory);
  const [searchParams] = useSearchParams();
  const paramId = searchParams.get("id");
  const paramName = searchParams.get("name");
  const [query, setQuery] = useState("");
  const [count, setCount] = useState(1);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 12;
  const [loading, setLoading] = useState(true);



  const uniqueSubCategories = [
    ...new Set(inventories.map((item) => item?.subcategory?.name)),
  ];

  useEffect(() => {
    if (paramId) {
      setLoading(true);
      dispatch(fetchInventories(paramId))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return () => {
      dispatch(clearInventories());
    };
  }, [dispatch, paramId]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    window.scrollTo(0, 0);
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleSubCategoryChange = (subcategoryId) => {
    console.log(subcategoryId);
    if (selectedSubCategories.includes(subcategoryId)) {
      setSelectedSubCategories((prev) =>
        prev.filter((id) => id !== subcategoryId)
      );
    } else {
      setSelectedSubCategories((prev) => [...prev, subcategoryId]);
    }
  };

  const filteredItems = inventories.filter((item) => {
    const matchesSearch = item.name.toLowerCase().includes(query.toLowerCase());
    const matchesSubcategory =
      selectedSubCategories.length === 0 ||
      selectedSubCategories.includes(item.subcategory?._id);
    return matchesSearch && matchesSubcategory;
  });

  const toggleSubCategory = () => {
    setIsSubCategoryOpen(!isSubCategoryOpen);
  };

  const toggleSize = () => {
    setIsSizeOpen(!isSizeOpen);
  };

  const toggleBrand = () => {
    setIsBrandOpen(!isBrandOpen);
  };

  const togglePrice = () => {
    setIsPriceOpen(!isPriceOpen);
  };

 
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredItems.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <TailSpin
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      </div>
    );
  }
  if (inventories.length === 0) {
    return (
      <div className="text-center text-2xl font-semibold p-10">
        No Items available!
      </div>
    );
  }

  const handleAddToWishlist = (e, item) => {
    e.stopPropagation();
    const isInWishlist = wishlistItems.some(
      (wishlistItem) => wishlistItem._id === item._id
    );
    if (isInWishlist) {
      dispatch(removeFromWishlist(item._id));
    } else {
      dispatch(addToWishlist(item));
    }
  };

  const handleAddToCart = (product) => {
    if (product) {
      dispatch(addToCart({ ...product, quantity: count }));
      toast.success("Item Added To Cart");
    }
  };

  return (
    <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px]">
      <div className="w-full flex md:flex-row flex-col gap-5">
        <div className="md:w-[22%] h-[90%] border border-gray-200 p-4 flex flex-col gap-5  rounded-3xl shadow-lg overflow-hidden md:sticky md:top-24 relative top-0 md:max-h-[80vh]">
          <div>
            <h1 className="text-2xl MediumFont">Filters</h1>
          </div>
          <div className="flex flex-col gap-4  overflow-y-auto max-h-[calc(100vh-120px)]">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={toggleSubCategory}
            >
              <p className="text-base MediumFont">Sub category</p>
              {isSubCategoryOpen ? (
                <FaChevronUp size={18} />
              ) : (
                <FaChevronDown size={18} />
              )}
            </div>
            {isSubCategoryOpen && (
              <div className="flex flex-col gap-2 ">
                {inventories
                  .map((item) => item.subcategory)
                  .filter(
                    (subcategory, index, self) =>
                      subcategory &&
                      self.findIndex((s) => s?._id === subcategory._id) ===
                        index
                  )
                  .map((subcategory) => (
                    <div
                      key={subcategory._id}
                      className="flex justify-between items-center"
                    >
                      <p className="text-base NormalFont w-[80%]">
                        {subcategory?.name}
                      </p>
                      <input
                        type="checkbox"
                        className="w-4 h-4 mr-2 "
                        checked={selectedSubCategories.includes(
                          subcategory._id
                        )}
                        onChange={() =>
                          handleSubCategoryChange(subcategory._id)
                        }
                      />
                    </div>
                  ))}
              </div>
            )}

            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={toggleSize}
            >
              <p className="text-base MediumFont">Quantity</p>
              {isSizeOpen ? (
                <FaChevronUp size={18} />
              ) : (
                <FaChevronDown size={18} />
              )}
            </div>
            {isSizeOpen && (
              <div className="flex flex-col gap-2">
                {["1 Kg", "2 Kg", "3 Kg", "4 Kg", "5 Kg"].map((size, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <p className="text-base NormalFont">{size}</p>
                    <input type="checkbox" className="w-4 h-4" />
                  </div>
                ))}
              </div>
            )}

            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={togglePrice}
            >
              <p className="text-base MediumFont">Price</p>
              {isPriceOpen ? (
                <FaChevronUp size={18} />
              ) : (
                <FaChevronDown size={18} />
              )}
            </div>
            {isPriceOpen && (
              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <p className="text-base NormalFont">$0 - $50</p>
                  <input type="checkbox" className="w-4 h-4" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="md:w-[78%]">
          <div className="flex justify-between">
            <p className="MediumFont text-2xl text-[#344054] capitalize">
              {paramName}
            </p>
            <div className="hidden sm:flex  items-center justify-between border border-[#D0D5DD] rounded-2xl py-[8px] px-[12px]">
              <input
                type="text"
                value={query}
                onChange={handleSearch}
                placeholder="Search products..."
                className="outline-none w-full"
              />
              <CiSearch size={24} />
            </div>
          </div>

          <div className="mt-6 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 px-2 ">
              {filteredItems.length > 0 ? (
                currentItems.map((item) => (
                  <Link
                    key={item._id}
                    to={`/product/${item._id}`}
                    className="flex flex-col gap-4 md:h-[350px] h-[520px] group shadow-md rounded-2xl overflow-hidden relative transition-all duration-300 md:hover:h-[470px] hover:h-[620px]"
                  >
                    <div className="relative">
                      <img
                        src={item.imageUrl}
                        alt=""
                        className="bg-cover md:h-[200px] w-full rounded-b-xl"
                      />
                      <div
                        className="absolute top-1 right-2 w-[44px] h-[44px] rounded-full flex items-center justify-center cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleAddToWishlist(e, item);
                        }}
                      >
                        {wishlistItems.some(
                          (wishlistItem) => wishlistItem._id === item._id
                        ) ? (
                          <FaHeart className="text-red-500" size={28} />
                        ) : (
                          <FaRegHeart className="text-gray-300" size={28} />
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 items-center justify-center my-4 px-4">
                      <p className="MediumFont text-xl text-[#344054] text-center w-[180px]">
                        {item.name.length > 24
                          ? item.name.slice(0, 24) + "..."
                          : item.name}
                      </p>

                      {item.retailPrice > 0 && (
                        <p className="text-[16px] text-[#DC4D41] line-through RegularFont">
                          Eur {item.retailPrice}
                        </p>
                      )}
                      <p className="font-medium text-lg my-2 text-[#344054] MediumFont">
                        Eur {item.price}
                      </p>
                    </div>

                    <div className="absolute bottom-0 left-0 right-0 px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <p className="font-medium text-center text-base text-[#344054]">
                        {item.description.split(" ").length > 10
                          ? item.description.split(" ").slice(0, 10).join(" ") +
                            "..."
                          : item.description}
                      </p>
                      <div className="flex items-center flex-col gap-2 mt-2">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleAddToCart(item);
                          }}
                          className={`flex items-center justify-center ${
                            cartItems.some(
                              (cartItems) => cartItems._id === item._id
                            )
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-white"
                          } rounded-full p-[5px]`}
                        >
                          <PiShoppingCartLight size={32} color="#003E00" />
                        </button>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p>No items available.</p>
              )}
            </div>

            <div className="mt-10 flex justify-center">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                breakClassName={"page-item"}
                pageClassName={"page-item"}
                disabledClassName={"disabled"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSearch;
