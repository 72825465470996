// import React, { useRef, useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import {
//   addToWishlist,
//   removeFromWishlist,
// } from "../../redux/slices/wishlistSlice";
// import icon1 from "../../assets/images/Chevron left.png";
// import icon2 from "../../assets/images/Chevron right.png";
// import icon3 from "../../assets/images/heart.png";
// import Slider from "react-slick";
// import { addToCart } from "../../redux/slices/cartSlice";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import CustomLink from "../CustomLink/CustomLink";
// import "../../../src/index.css";
// import API from "../../api/apiService";
// import { ENDPOINTS } from "../../api/apiRoutes";
// import { FaRegHeart, FaHeart } from "react-icons/fa";
// import "../../index.css";
// import { toast } from "react-toastify";
// import "./PeopleBuy.css";
// import { PiShoppingCartLight } from "react-icons/pi";

// function PeopleBuy() {
//   const dispatch = useDispatch();
//   const wishlistItems = useSelector((state) => state.wishlist.items);
//   const sliderRefs = useRef([]);
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const [categories, setCategories] = useState([]);
//   const [visibleCount, setVisibleCount] = useState(3);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [count, setCount] = useState(1);

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await API.get(ENDPOINTS.GET_ALLPRODUCTS);
//         console.log("API Response:", response);

//         const products = response;

//         if (!Array.isArray(products)) {
//           throw new Error("Expected an array of products");
//         }

//         const uniqueCategoriesMap = new Map();

//         products.forEach((item) => {
//           if (item.category && item.category._id && item.category.name) {
//             const categoryId = item.category._id;
//             const categoryName = item.category.name;

//             if (uniqueCategoriesMap.has(categoryId)) {
//               uniqueCategoriesMap.get(categoryId).products.push(item);
//             } else {
//               uniqueCategoriesMap.set(categoryId, {
//                 _id: categoryId,
//                 name: categoryName,
//                 products: [item],
//               });
//             }
//           }
//         });

//         const uniqueCategories = Array.from(uniqueCategoriesMap.values());
//         setCategories(uniqueCategories);
//         console.log("Unique Categories:", uniqueCategories);
//       } catch (err) {
//         console.error("Error fetching categories:", err);
//         setError(err.message || "Error fetching categories");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCategories();
//   }, []);

//   const settings = {
//     infinite: true,
//     speed: 1000,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     initialSlide: 0,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           initialSlide: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const handlePrevClick = () => {
//     sliderRefs.current.forEach((slider) => {
//       if (slider) {
//         slider.slickPrev();
//       }
//     });
//   };

//   const handleNextClick = () => {
//     sliderRefs.current.forEach((slider) => {
//       if (slider) {
//         slider.slickNext();
//       }
//     });
//   };

//   const handleSeeMoreClick = () => {
//     setVisibleCount(categories.length);
//   };

//   const handleAddToWishlist = (e, product) => {
//     e.stopPropagation();
//     const isInWishlist = wishlistItems.some((item) => item._id === product._id);
//     if (isInWishlist) {
//       dispatch(removeFromWishlist(product._id));
//     } else {
//       dispatch(addToWishlist(product));
//     }
//   };

//   if (loading) {
//     return <div className="text-center">Loading...</div>;
//   }

//   if (error) {
//     return <div className="text-center text-red-500">Item Not Found</div>;
//   }

//   if (categories.length === 0) {
//     return <div className="text-center">No categories available.</div>;
//   }

//   const handleAddToCart = (product) => {
//     if (product) {
//       dispatch(
//         addToCart({ ...product, quantity: count })
//       );
//       toast.success("Item Added To Cart");
//     }
//   };

//   return (
//     <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px] mt-10">
//       <div className="flex justify-between items-center ">
//         <div>
//           <p className="MediumFont text-2xl text-[#344054]">People Also Buy</p>
//         </div>
//         <div className="flex gap-[16px]">
//           <img
//             src={icon1}
//             alt="Previous"
//             onClick={handlePrevClick}
//             className="cursor-pointer"
//           />
//           <img
//             src={icon2}
//             alt="Next"
//             onClick={handleNextClick}
//             className="cursor-pointer"
//           />
//         </div>
//       </div>

//       {categories.slice(0, visibleCount).map((category, index) => {
//         return (
//           <div key={category._id} className="mt-6">
//             <div className="flex justify-between"></div>

//             <div className="mt-6 w-full">
//               <Slider
//                 ref={(el) => (sliderRefs.current[index] = el)}
//                 {...settings}
//                 className="slider"
//               >
//                 {category.products.map((product) => (
//                   <Link
//                     key={product._id}
//                     to={`/product/${product._id}`}
//                     className="flex flex-col gap-4 shadow-md rounded-2xl overflow-hidden relative group" // Added 'group' class
//                   >
//                     <div className="relative">
//                       <div className="">
//                         <img
//                           src={product.imageUrl}
//                           alt=""
//                           className="bg-cover md:h-[170px] w-full rounded-b-xl"
//                         />
//                       </div>
//                       <div
//                         className="absolute top-1 right-2 w-[44px] h-[44px] rounded-full flex items-center justify-center cursor-pointer"
//                         onClick={(e) => {
//                           e.preventDefault();
//                           e.stopPropagation();
//                           handleAddToWishlist(e, product);
//                         }}
//                       >
//                         {wishlistItems.some(
//                           (item) => item._id === product._id
//                         ) ? (
//                           <FaHeart className="text-red-500" size={28} />
//                         ) : (
//                           <FaRegHeart className="text-white" size={28} />
//                         )}
//                       </div>
//                     </div>

//                     <div className="flex flex-col gap-2 items-center justify-center my-3 px-4 ">
//                       <p className="MediumFont text-xl text-[#344054] text-center">
//                         {product.name}
//                       </p>
//                       <p className=" text-xl text-gray-500 text-center">
//                         {category.name}
//                       </p>
//                       {product.retailPrice > 0 && (
//                         <p className="text-[16px] text-[#DC4D41] line-through RegularFont">
//                           Eur {product.retailPrice}
//                         </p>
//                       )}
//                       <p className="font-medium text-lg text-[#344054] MediumFont">
//                         Eur {product.price}
//                       </p>
//                     </div>

//                     <div className="item-center justify-center px-4 py-2 hidden group-hover:block">
//                       {" "}
//                       <div>
//                         <p className="font-medium text-center text-base  text-[#344054] ">
//                           {product.description}
//                         </p>
//                       </div>
//                       <div className="flex items-center flex-col gap-2 ">
//                         <button
//                           onClick={(e) => {
//                             e.preventDefault();
//                             e.stopPropagation();
//                             handleAddToCart(product);
//                           }}
//                           disabled={cartItems.some(
//                             (item) => item._id === product._id
//                           )}
//                           className={`flex items-center justify-center ${
//                             cartItems.some((item) => item._id === product._id)
//                               ? "bg-gray-300 cursor-not-allowed"
//                               : "bg-white"
//                           } rounded-full p-[5px]`}
//                         >
//                           <PiShoppingCartLight size={32} color="#003E00" />
//                         </button>
//                       </div>
//                     </div>
//                   </Link>
//                 ))}
//               </Slider>
//             </div>
//           </div>
//         );
//       })}

//       {visibleCount < categories.length && (
//         <div className="text-center mt-8">
//           <button
//             onClick={handleSeeMoreClick}
//             className="bg-[#003E00] text-xl text-white py-3 px-6 rounded-md MediumFont"
//           >
//             See More
//           </button>
//         </div>
//       )}
//     </div>
//   );
// }

// export default PeopleBuy;

// import React, { useRef, useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import {
//   addToWishlist,
//   removeFromWishlist,
// } from "../../redux/slices/wishlistSlice";
// import icon1 from "../../assets/images/Chevron left.png";
// import icon2 from "../../assets/images/Chevron right.png";
// import icon3 from "../../assets/images/heart.png";
// import Slider from "react-slick";
// import { addToCart } from "../../redux/slices/cartSlice";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import CustomLink from "../CustomLink/CustomLink";
// import "../../../src/index.css";
// import API from "../../api/apiService";
// import { ENDPOINTS } from "../../api/apiRoutes";
// import { FaRegHeart, FaHeart } from "react-icons/fa";
// import "../../index.css";
// import { toast } from "react-toastify";
// import "./PeopleBuy.css";
// import { PiShoppingCartLight } from "react-icons/pi";
// import ReactPaginate from "react-paginate";

// function PeopleBuy() {
//   const dispatch = useDispatch();
//   const wishlistItems = useSelector((state) => state.wishlist.items);
//   const sliderRefs = useRef([]);
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const [categories, setCategories] = useState([]);
//   const [visibleCount, setVisibleCount] = useState(3);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [count, setCount] = useState(1);
//   const [currentPage, setCurrentPage] = useState(0); // State for current page
//   const itemsPerPage = 3; // Number of items per page

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await API.get(ENDPOINTS.GET_ALLPRODUCTS);
//         console.log("API Response:", response);

//         const products = response;

//         if (!Array.isArray(products)) {
//           throw new Error("Expected an array of products");
//         }

//         const uniqueCategoriesMap = new Map();

//         products.forEach((item) => {
//           if (item.category && item.category._id && item.category.name) {
//             const categoryId = item.category._id;
//             const categoryName = item.category.name;

//             if (uniqueCategoriesMap.has(categoryId)) {
//               uniqueCategoriesMap.get(categoryId).products.push(item);
//             } else {
//               uniqueCategoriesMap.set(categoryId, {
//                 _id: categoryId,
//                 name: categoryName,
//                 products: [item],
//               });
//             }
//           }
//         });

//         const uniqueCategories = Array.from(uniqueCategoriesMap.values());
//         setCategories(uniqueCategories);
//         console.log("Unique Categories:", uniqueCategories);
//       } catch (err) {
//         console.error("Error fetching categories:", err);
//         setError(err.message || "Error fetching categories");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCategories();
//   }, []);

//   const settings = {
//     infinite: true,
//     speed: 1000,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     initialSlide: 0,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           initialSlide: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const handlePrevClick = () => {
//     sliderRefs.current.forEach((slider) => {
//       if (slider) {
//         slider.slickPrev();
//       }
//     });
//   };

//   const handleNextClick = () => {
//     sliderRefs.current.forEach((slider) => {
//       if (slider) {
//         slider.slickNext();
//       }
//     });
//   };

//   const handlePageClick = ({ selected }) => {
//     setCurrentPage(selected);
//   };

//   const handleSeeMoreClick = () => {
//     setVisibleCount(categories.length);
//   };

//   const handleAddToWishlist = (e, product) => {
//     e.stopPropagation();
//     const isInWishlist = wishlistItems.some((item) => item._id === product._id);
//     if (isInWishlist) {
//       dispatch(removeFromWishlist(product._id));
//     } else {
//       dispatch(addToWishlist(product));
//     }
//   };

//   if (loading) {
//     return <div className="text-center">Loading...</div>;
//   }

//   if (error) {
//     return <div className="text-center text-red-500">Item Not Found</div>;
//   }

//   if (categories.length === 0) {
//     return <div className="text-center">No categories available.</div>;
//   }

//   const handleAddToCart = (product) => {
//     if (product) {
//       dispatch(addToCart({ ...product, quantity: count }));
//       toast.success("Item Added To Cart");
//     }
//   };

//   const pageCount = Math.ceil(categories.length / itemsPerPage);
//   const displayedCategories = categories.slice(
//     currentPage * itemsPerPage,
//     (currentPage + 1) * itemsPerPage
//   );

//   return (
//     <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px] mt-10">
//       <div className="flex justify-between items-center ">
//         <div>
//           <p className="MediumFont text-2xl text-[#344054]">People Also Buy</p>
//         </div>
//         <div className="flex gap-[16px]">
//           <img
//             src={icon1}
//             alt="Previous"
//             onClick={handlePrevClick}
//             className="cursor-pointer"
//           />
//           <img
//             src={icon2}
//             alt="Next"
//             onClick={handleNextClick}
//             className="cursor-pointer"
//           />
//         </div>
//       </div>

//       {displayedCategories.map((category, index) => {
//         return (
//           <div key={category._id} className="mt-6">
//             <div className="flex justify-between"></div>

//             <div className="mt-6 w-full">
//               <Slider
//                 ref={(el) => (sliderRefs.current[index] = el)}
//                 {...settings}
//                 className="slider"
//               >
//                 {category.products.map((product) => (
//                   <Link
//                     key={product._id}
//                     to={`/product/${product._id}`}
//                     className="flex flex-col gap-4 shadow-md rounded-2xl overflow-hidden relative group" // Added 'group' class
//                   >
//                     <div className="relative">
//                       <div className="">
//                         <img
//                           src={product.imageUrl}
//                           alt=""
//                           className="bg-cover md:h-[170px] w-full rounded-b-xl"
//                         />
//                       </div>
//                       <div
//                         className="absolute top-1 right-2 w-[44px] h-[44px] rounded-full flex items-center justify-center cursor-pointer"
//                         onClick={(e) => {
//                           e.preventDefault();
//                           e.stopPropagation();
//                           handleAddToWishlist(e, product);
//                         }}
//                       >
//                         {wishlistItems.some(
//                           (item) => item._id === product._id
//                         ) ? (
//                           <FaHeart className="text-red-500" size={28} />
//                         ) : (
//                           <FaRegHeart className="text-white" size={28} />
//                         )}
//                       </div>
//                     </div>

//                     <div className="flex flex-col gap-2 items-center justify-center my-3 px-4 ">
//                     <p className="MediumFont text-xl text-[#344054] text-center">
//                             {product.name.split(" ").slice(0, 2).join(" ") +
//                               (product.name.split(" ").length > 2
//                                 ? " ..."
//                                 : "")}
//                           </p>
//                       <p className=" text-xl text-gray-500 text-center">
//                         {category.name}
//                       </p>
//                       {product.retailPrice > 0 && (
//                         <p className="text-[16px] text-[#DC4D41] line-through RegularFont">
//                           Eur {product.retailPrice}
//                         </p>
//                       )}
//                       <p className="font-medium text-lg text-[#344054] MediumFont">
//                         Eur {product.price}
//                       </p>
//                     </div>

//                     <div className="item-center justify-center px-4 py-2 hidden group-hover:block">
//                       {" "}
//                       <div>
//                       <p className="font-medium text-center text-base  text-[#344054] ">
//                               {product.description.split(" ").length > 8
//                                 ? product.description
//                                     .split(" ")
//                                     .slice(0, 8)
//                                     .join(" ") + " ..."
//                                 : product.description}
//                             </p>
//                       </div>
//                       <div className="flex items-center flex-col gap-2 ">
//                         <button
//                           onClick={(e) => {
//                             e.preventDefault();
//                             e.stopPropagation();
//                             handleAddToCart(product);
//                           }}
//                           disabled={cartItems.some(
//                             (item) => item._id === product._id
//                           )}
//                           className={`flex items-center justify-center ${
//                             cartItems.some((item) => item._id === product._id)
//                               ? "bg-gray-300 cursor-not-allowed"
//                               : "bg-white"
//                           } rounded-full p-[5px]`}
//                         >
//                           <PiShoppingCartLight size={32} color="#003E00" />
//                         </button>
//                       </div>
//                     </div>
//                   </Link>
//                 ))}
//               </Slider>
//             </div>
//           </div>
//         );
//       })}

//       <div className="mt-4 flex justify-center">
//         <ReactPaginate
//           previousLabel={"Previous"}
//           nextLabel={"Next"}
//           pageCount={pageCount}
//           onPageChange={handlePageClick}
//           containerClassName={"pagination"}
//           activeClassName={"active"}
//           previousClassName={"page-item"}
//           nextClassName={"page-item"}
//           breakClassName={"page-item"}
//           pageClassName={"page-item"}
//           disabledClassName={"disabled"}
//         />
//       </div>
//     </div>
//   );
// }

// export default PeopleBuy;


// code after upload data by ihtizaz

import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/slices/wishlistSlice";
import icon1 from "../../assets/images/Chevron left.png";
import icon2 from "../../assets/images/Chevron right.png";
import icon3 from "../../assets/images/heart.png";
import Slider from "react-slick";
import { addToCart } from "../../redux/slices/cartSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomLink from "../CustomLink/CustomLink";
import "../../../src/index.css";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import "../../index.css";
import { toast } from "react-toastify";
import "./PeopleBuy.css";
import { PiShoppingCartLight } from "react-icons/pi";
import ReactPaginate from "react-paginate";
import { TailSpin } from 'react-loader-spinner';



function PeopleBuy() {
  const { searchQuery } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const sliderRefs = useRef([]);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [categories, setCategories] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); 
  const categoriesPerPage = 3; 
  const [totalPages, setTotalPages] = useState(0);


    const fetchCategories = async (page) => {
      try {
        setLoading(true);
        const response = await API.get(
          `${ENDPOINTS.GET_ALLPRODUCTS}?page=${page}&limit=${categoriesPerPage}`);
        const products = response.inventoryItems || [];
        const totalItems = response.totalItems || 0; 
  
        if (!Array.isArray(products)) {
          throw new Error("Expected an array of products");
        }
  
        const uniqueCategoriesMap = new Map();
        products.forEach((item) => {
          if (item.category && item.category._id && item.category.name) {
            const categoryId = item.category._id;
            if (uniqueCategoriesMap.has(categoryId)) {
              uniqueCategoriesMap.get(categoryId).products.push(item);
            } else {
              uniqueCategoriesMap.set(categoryId, {
                _id: categoryId,
                name: item.category.name,
                products: [item],
              });
            }
          }
        });
  
        setCategories(Array.from(uniqueCategoriesMap.values()));
        setTotalPages(Math.ceil(totalItems / categoriesPerPage)); 
      } catch (err) {
        setError(err.message || "Error fetching categories");
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchCategories(currentPage); 
    }, [currentPage]);

    const filteredCategories = categories
    .map((category) => ({
      ...category,
      products:
        category.products?.filter((product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) || [],
    }))
    .filter((category) => category.products.length > 0);

  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots:false
          
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) {
        slider.slickPrev();
      }
    });
  };

  const handleNextClick = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) {
        slider.slickNext();
      }
    });
  };


  const handleSeeMoreClick = () => {
    setVisibleCount(categories.length);
  };

  const handleAddToWishlist = (e, product) => {
    e.stopPropagation();
    const isInWishlist = wishlistItems.some((item) => item._id === product._id);
    if (isInWishlist) {
      dispatch(removeFromWishlist(product._id));
    } else {
      dispatch(addToWishlist(product));
    }
  };

 

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <TailSpin
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      </div>
    );
  }
  if (error) {
    return <div className="text-center text-red-500">Item Not Found</div>;
  }

  if (categories.length === 0) {
    return <div className="text-center">No categories available.</div>;
  }

  const handleAddToCart = (product) => {
    if (product) {
      dispatch(addToCart({ ...product, quantity: count }));
      toast.success("Item Added To Cart");
    }
  };

  const offset = (currentPage - 1) * categoriesPerPage;
  const displayedCategories = filteredCategories.slice(
    offset,
    offset + categoriesPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1); 
  };

  return (
    <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px] mt-10">
      <div className="flex justify-between items-center ">
        <div>
          <p className="MediumFont text-2xl text-[#344054]">People Also Buy</p>
        </div>
        <div className="flex gap-[16px]">
          <img
            src={icon1}
            alt="Previous"
            onClick={handlePrevClick}
            className="cursor-pointer"
          />
          <img
            src={icon2}
            alt="Next"
            onClick={handleNextClick}
            className="cursor-pointer"
          />
        </div>
      </div>

      {filteredCategories.map((category, index) => {
        return (
          <div key={category._id} className="mt-6">
            <div className="flex justify-between"></div>

            <div className="mt-6 w-full">
              <Slider
                ref={(el) => (sliderRefs.current[index] = el)}
                {...settings}
                className="slider"
              >
                 {category.products.map((product) => (
                      <Link
                        key={product._id}
                        to={`/product/${product._id}`}
                        className="flex flex-col gap-4 md:h-[380px] h-[520px] group shadow-md rounded-2xl overflow-hidden relative transition-all duration-300 md:hover:h-[500px] hover:h-[620px]"
                        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                      >
                        <div className="relative">
                          <img
                            src={product.imageUrl}
                            alt=""
                            className="bg-cover md:h-[200px] w-full rounded-b-xl"
                          />
                          <div
                            className="absolute top-1 right-2 w-[44px] h-[44px] rounded-full flex items-center justify-center cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddToWishlist(e, product);
                            }}
                          >
                            {wishlistItems.some(
                              (item) => item._id === product._id
                            ) ? (
                              <FaHeart className="text-red-500" size={28} />
                            ) : (
                              <FaRegHeart className="text-gray-300" size={28} />
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col gap-2 items-center justify-center my-4 px-4">
                          <p className="MediumFont text-xl text-[#344054] text-center w-[180px]">
                            {product.name.length > 24
                              ? product.name.slice(0, 24) + "..."
                              : product.name}
                          </p>
                          <p className="text-xl text-gray-500 text-center">
                            {category.name}
                          </p>

                          {product.retailPrice > 0 && (
                            <p className="text-[16px] text-[#DC4D41] line-through RegularFont">
                              Eur {product.retailPrice}
                            </p>
                          )}
                          <p className="font-medium text-lg my-2 text-[#344054] MediumFont">
                            Eur {product.price}
                          </p>
                        </div>

                        {/* Hidden description and button, visible on hover */}
                        <div className="absolute bottom-0 left-0 right-0 px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <p className="font-medium text-center text-base text-[#344054]">
                            {product.description.split(" ").length > 10
                              ? product.description
                                  .split(" ")
                                  .slice(0, 10)
                                  .join(" ") + "..."
                              : product.description}
                          </p>
                          <div className="flex items-center flex-col gap-2 mt-2">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleAddToCart(product);
                              }}
                              disabled={cartItems.some(
                                (item) => item._id === product._id
                              )}
                              className={`flex items-center justify-center ${
                                cartItems.some(
                                  (item) => item._id === product._id
                                )
                                  ? "bg-gray-300 cursor-not-allowed"
                                  : "bg-white"
                              } rounded-full p-[5px]`}
                            >
                              <PiShoppingCartLight size={32} color="#003E00" />
                            </button>
                          </div>
                        </div>
                      </Link>
                ))}
              </Slider>
            </div>
          </div>
        );
      })}

      <div className="mt-4 flex justify-center">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={totalPages}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          breakClassName={"page-item"}
          pageClassName={"page-item"}
          disabledClassName={"disabled"}
        />
      </div>
    </div>
  );
}

export default PeopleBuy;